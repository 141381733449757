import React, { useState, useEffect } from 'react';

import './App.css';
import { CssBaseline } from '@material-ui/core';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

import { fetchAPI } from "./config/api";

import NavBarContext from './contexts/NavBarContext';
import Main from './components/Main';


let theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: "Helvetica",
  },

});

theme = responsiveFontSizes(theme);

const App = () => {

  const [navigationInfo, setNavigationInfo] = useState();

  useEffect(() => {
    fetchAPI("/navigation/render/main-navigation?type=tree").then(data => {
      setNavigationInfo(data);
    });
  }, []);

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBarContext.Provider value={[navigationInfo, setNavigationInfo]}>
        <Main />
      </NavBarContext.Provider>
    </ThemeProvider >
  );
}

export default App;
