import React, { useState } from 'react'
import ImageGalleryItemLayout from '../../components/PageLayout/ImageGallery/ItemLayout';
import { useLocation } from "react-router-dom";

const ImageGalleryItem = (props) => {

    const location = useLocation();

    const getUrlHandler = (urn) => {
        return location.pathname + "/" + urn;
    }

    const [isInfoShown, setIsInfoShown] = useState(false);

    const showInfoHandler = () => {
        setIsInfoShown(true)
    }

    const hideInfoHandler = () => {
        setIsInfoShown(false)
    }

    return (
        <ImageGalleryItemLayout
            isInfoShown={isInfoShown}
            showInfo={showInfoHandler}
            hideInfo={hideInfoHandler}
            item={props.item}
            getUrl={getUrlHandler}
        />
    )
}

export default ImageGalleryItem


