import React, { useState } from 'react'

import EcoBarComponent from '../../components/ResponsiveToolbar/EcoBar';

const EcoBar = () => {
    const [isDrawerOpenState, toggleDrawerHandler] = useState(false);

    const handleDrawerClose = () => {
        toggleDrawerHandler(false);
    }

    const handleDrawerOpen = () => {
        toggleDrawerHandler(true);
    }


    return (
        <EcoBarComponent
            isDrawerOpen={isDrawerOpenState}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
        />
    )
}

export default EcoBar
