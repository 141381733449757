import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import Logo from '../Logo';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: "56.25%", /* Player ratio: 100 / (1280 / 720) */
        marginRight: '25%',
        marginBottom: theme.spacing(4),
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}));


const VideoLayout = (props) => {

    const [ready, setReady] = useState([]);

    const classes = useStyles();

    let { url } = props;

    function updateReadyList(element) {
        setReady([...ready, element]);
    }

    const elementComponent =
        <div style={{ top: 0, position: "absolute", width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, .1)" }}>
            <Hidden smDown >
                <Logo />
            </Hidden>
        </div>

    return (
        <div className={classes.wrapper}>

            {!ready.includes(url) && elementComponent}
            <ReactPlayer
                url={url}
                className={classes.player}
                playing={url.includes("vimeo")}
                onReady={() => updateReadyList(url)}
                controls={false}
                width='100%'
                height='100%'
            />
        </div >
    )
}

export default VideoLayout
