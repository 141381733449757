import React from 'react'
import { Grid } from '@material-ui/core'
import Thumbnail from './Thumbnail';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "2%",
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: "row"
        },
        backgroundColor: 'white',
    },
    main: {
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(2),
        },
        flexDirection: "column",
        flexWrap: 'noWrap',
    }
}));


const NewsLayout = (props) => {

    const classes = useStyles();

    const { posts } = props;

    let postElements = posts && posts.map(post => (
        <Thumbnail key={post.id} post={post} />
    ));

    return (
        <div className={classes.root}>
            <Grid container className={classes.main} direction='column'>
                {postElements}
            </Grid>
        </div>

    )
}

export default NewsLayout
