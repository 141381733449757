import React, { useState, useEffect } from 'react'
import LocaleSelector from '../../components/ResponsiveToolbar/LocaleSelector'
import { fetchAPI } from "../../config/api";
import { useTranslation } from 'react-i18next';



const Locales = () => {

    const { i18n } = useTranslation();

    const [locales, setLocales] = useState([]);

    const [activeLocale, setActiveLocale] = useState(i18n.language || 'en');

    function setActiveLocaleHandler(newLocale) {
        setActiveLocale(newLocale);
        i18n.changeLanguage(newLocale);
    }

    useEffect(() => {
        fetchAPI("/i18n/locales").then(data => {

            let result = []

            data.forEach(element => {
                result.push(element.code)
            });

            if (result.length === 0) {
                result.push('en')
            }

            setLocales(result);
        })
    }, [])

    return (
        <LocaleSelector supportedLocales={locales} activeLocale={activeLocale} setActiveLocale={setActiveLocaleHandler} />
    )
}


export default Locales;
