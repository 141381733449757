import { makeStyles } from '@material-ui/core';
import React from 'react'
import { getStrapiURL } from '../../../config/api';

const useStyles = makeStyles((theme) => ({
    fitHeight: {
        //height: '100%',
        //width: 'auto',
    },
}));


const ImageLayout = (props) => {

    const classes = useStyles();

    const { image } = props;

    return (
        <picture>
            <source
                media="(max-width: 1023px)"
                sizes='80vw'
                srcSet={`${getStrapiURL(image.url)} ${image.width}w`}
            />
            <source
                media="(max-width: 1024px)"
                sizes={image.width > image.height ? '50vw' : '24vw'}
                srcSet={`${getStrapiURL(image.url)} ${image.width}w`}
            />
            <img className={classes.fitHeight}
                src={`${getStrapiURL(image.formats.thumbnail ? image.formats.thumbnail.url : image.url)}`}
                alt={props.title} sizes={image.width / image.height > 1.6 || image.width > 4000 ? '60vw' : '29vw'}
                srcSet={`${getStrapiURL(image.url)} ${image.width}w`}
            />
        </picture>
    )
}

export default ImageLayout