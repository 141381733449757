import React from 'react'
import BrowserItem from './BrowserItem'
import MobileItem from './MobileItem'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';





const ImageGalleryItemLayout = (props) => {

    const theme = useTheme();

    const matches_lg = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        matches_lg ? <BrowserItem  {...props} /> : <MobileItem {...props} />
    )
}

export default ImageGalleryItemLayout
