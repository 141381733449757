import React from 'react';
import logo from '../icons/logo.svg';
// import companyName from '../icons/companyName.svg';

// import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    logoGrid: {
        zIndex: 1099,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        [theme.breakpoints.between('xs', '600')]: {
            position: "fixed",
        },
        [theme.breakpoints.up('sm')]: {
            position: "absolute",
        },
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    companyNameWrapper: {
        position: "absolute",
        pointerEvents: "none",
        height: "10%",
    },
    logoWrapper: {
        [theme.breakpoints.up('sm')]: {
            height: "80%",
        },
        [theme.breakpoints.between('300', '600')]: {
            height: "22%",
        },
        [theme.breakpoints.between('xs', '300')]: {
            height: "20%",
        },
    }
}));

const Logo = () => {

    const classes = useStyles();

    return (
        <Box className={classes.logoGrid}>
            <img src={logo} className={`App-logo ${classes.logoWrapper}`} alt="logo" />
            {/* <Hidden xsDown>
                <img src={companyName} className={classes.companyNameWrapper} alt="logo" />
            </Hidden> */}
        </Box>
    )
}

export default Logo
