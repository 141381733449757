import { makeStyles } from '@material-ui/core';
import React from 'react'
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import ScrollToTop from '../containers/General/ScrollToTop'

import Page from '../containers/Page/Page';
import Home from '../components/Home';
import News from '../containers/General/News';
import Contact from '../containers/General/Contact';
import PageNotFound from '../components/General/UnderConstruction';

import ResponsiveToolbar from './ResponsiveToolbar/ResponsiveToolbar';
import FooterLayout from './General/FooterLayout';



const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: "row",
            marginTop: "42px",
        },
        backgroundColor: 'white',
    },
    mainWrapper: {
        width: '100%',
    },
    windowWrapper: {
        minHeight: '100vh'
    }
}));

const Main = () => {

    const classes = useStyles();

    return (
        <Router>
            <div className={classes.root}>
                <ScrollToTop />
                <ResponsiveToolbar />
                <div className={classes.mainWrapper}>
                    <div className={classes.windowWrapper}>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/news' component={News} />
                            <Route exact path='/contact' component={Contact} />
                            <Route exact path='/pnf' component={PageNotFound} />
                            <Route exact path='/:slug' component={Page} />
                            <Route exact path="/:nav/:subNav" component={Page} />
                        </Switch>
                    </div>
                    <FooterLayout />
                </div>
            </div>
        </Router >
    )
}

export default Main
