import { Box, IconButton, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    info: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
    },
    textWrapper: {
        position: 'relative',
        padding: theme.spacing(2),
        backgroundColor: 'white',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',

    },
    collapseIcon: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        margin: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    description: {
        position: 'absolute',
        bottom: '0',
        left: 0,
        textAlign: 'center',
        width: '100%',
        color: 'black',
    }
}));

const InfoItem = (props) => {
    const classes = useStyles();

    const { item } = props;

    let collapseElement = null;

    if (!props.isBrowser) {
        collapseElement =
            <IconButton className={classes.collapseIcon} onClick={props.action}>
                <CloseIcon style={{ color: 'grey' }} />
            </IconButton>
    }

    return (

        <Box className={classes.info}>
            <Link to={props.getUrl(item.page.navigation.path)} >
                <Box className={classes.textWrapper}>
                    <Box className={classes.title}>
                        <Typography variant="h6" style={{ color: 'black', textDecoration: 'underline' }}>
                            {item.page.Title}
                        </Typography>
                    </Box>
                    <Box className={classes.description}>
                        <Typography variant="subtitle1">
                            {item.page.Description}
                        </Typography>
                    </Box>
                </Box>
            </Link>
            {collapseElement}
        </Box>
    )
}

export default InfoItem
