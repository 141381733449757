import React from 'react'
import { getStrapiURL } from '../../config/api'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';



const useStyles = makeStyles((theme) => ({
    image: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
}));

const ImageLayout = (props) => {
    const classes = useStyles();

    let { image } = props;

    const theme = useTheme();
    const matches_xl = useMediaQuery(theme.breakpoints.up('xl'));
    const matches_lg = useMediaQuery(theme.breakpoints.up('lg'));
    const matches_md = useMediaQuery(theme.breakpoints.up('md'));
    const matches_sm = useMediaQuery(theme.breakpoints.up('sm'));
    const matches_xs = useMediaQuery(theme.breakpoints.up('xs'));

    let format;

    if (image && image.formats) {
        if (matches_xl && image.formats.xlarge) {
            format = image.formats.xlarge;
        } else if (matches_lg && image.formats.large) {
            format = image.formats.large;
        } else if (matches_md && image.formats.medium) {
            format = image.formats.medium;
        } else if (matches_sm && image.formats.small) {
            format = image.formats.small;
        } else if (matches_xs && image.formats.thumbnail) {
            format = image.formats.thumbnail;
        }

        if (!format) {
            format = image;
        }
    }

    return (
        <div className={classes.image}>
            {format && <img src={getStrapiURL(format.url)} alt="" width={`${format.width}px`} height={`${format.height}px`} />}
        </div>
    )
}

export default ImageLayout
