import React, { useState, useEffect } from 'react';

import ContactLayout from '../../components/Contact/ContactLayout'
import { fetchAPI } from "../../config/api";

const Contact = () => {

    const [contactInfo, setContactInfo] = useState();

    useEffect(() => {
        fetchAPI("/contact").then(data => {
            setContactInfo(data);
        });
    }, []);


    return (
        <ContactLayout contactInfo={contactInfo} />
    )
}

export default Contact
