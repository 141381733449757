import React from 'react'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedIn from '@material-ui/icons/LinkedIn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { ListItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        color: "black"
    }
}));

const SocialMediaIcons = (props) => {

    const classes = useStyles();

    const { socialAccounts } = props;

    return (
        <ListItem>

            {socialAccounts.Facebook ?
                <a
                    className={classes.link}
                    href={socialAccounts.Facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FacebookIcon />
                </a> : null}
            {socialAccounts.Twitter ?
                <a
                    className={classes.link}
                    href={socialAccounts.Twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <TwitterIcon />
                </a> : null}
            {socialAccounts.LinkedIn ?
                <a
                    className={classes.link}
                    href={socialAccounts.LinkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <LinkedIn />
                </a> : null}
            <Link
                className={classes.link}
                to='#'
                onClick={(e) => {
                    window.location = `mailto:${socialAccounts.Email}`
                    e.preventDefault();
                }}
            >
                <MailOutlineIcon />
            </Link>
        </ListItem>
    )
}

export default SocialMediaIcons
