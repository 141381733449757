
import React from 'react'
import { Box, Card, CardContent, Container, Grid, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { getStrapiURL } from '../../config/api'
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "3rem",
        marginLeft: "1rem",
    },
    layout: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: "row"
        },
        paddingLeft: 0,
        position: 'relative',

    },
    imageWrap: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: theme.spacing(3)
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingRight: '1em',
        },
        position: 'relative',
        display: 'inline-block',
    },
    image: {
    },
    articleWrap: {
        paddingLeft: 0,
    },
    titleWrap: {
        textDecoration: 'none',
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5em'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2em',
        },
        color: 'black',
    },
    info: {
        flexDirection: 'row',
        color: '#AAAAAA',
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
    },
    divider: {
        marginLeft: '1em',
        marginRight: '1em'
    },
    subTitleText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2em'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1em',
        },
    },
}));

const Thumbnail = (props) => {

    const { post } = props;
    const classes = useStyles();

    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.up('sm'));


    const articleElement = post &&
        <Card style={{ boxShadow: 'none' }}>
            <CardContent>
                <div className={classes.layout}>
                    <Box className={classes.imageWrap} style={{ paddingRight: matches_sm && `calc(300px - ${post.Preview_Photo.width}px)` }}>
                        <a href={post.ExternalLink} target="_blank" rel="noreferrer">
                            <img className={classes.image} src={getStrapiURL(post.Preview_Photo.url)} width={matches_sm ? `${post.Preview_Photo.width}` : '90%'} alt="" />
                        </a>
                    </Box>
                    <Container className={classes.articleWrap}>
                        <a href={post.ExternalLink} className={classes.titleWrap} target="_blank" rel="noreferrer">
                            <Typography className={classes.title} variant="h5">
                                <Box fontWeight="fontWeightBold">
                                    {post.Title}
                                </Box>
                            </Typography>
                        </a>
                        <Grid container className={classes.info}>
                            <Grid item>
                                <Typography className={classes.subTitleText} variant="subtitle2">
                                    {post.Author}
                                </Typography>
                            </Grid>
                            {post.Reference && <Grid item className={classes.divider}>|</Grid>}
                            <Grid item><Typography className={classes.subTitleText} variant="subtitle2">
                                {post.Reference}
                            </Typography>
                            </Grid>
                        </Grid>
                        <Typography className={classes.subTitleText} variant="subtitle1">
                            {post.Description}
                        </Typography>
                    </Container>
                </div>
            </CardContent>
        </Card>

    return (
        <Grid item className={classes.root} md={9} lg={8}>
            {articleElement}
        </Grid >
    )
}

export default Thumbnail
