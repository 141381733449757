import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


const resources = {
    en: {
        translation: {
            'GREEN BUSES': 'GREEN BUSES',
            'SAFE AIR': 'SAFE AIR',
            'TRAINING & SERVICES': 'TRAINING & SERVICES',
            'ICT & TELEMATICS': 'ICT & TELEMATICS',

            'Green Buses': 'Green Buses',
            'Green Technology': 'Green Technology',
            'Public Transports': 'Public Transports',
            'Private Operators': 'Private Operators',
            'ECO Bus Simulators': 'ECO Bus Simulators',
            'Bus Depots': 'Bus Depots',
            'ECO Bus Scanners': 'ECO Bus Scanners',

            'Safe Air': 'Safe Air',
            'ECO Viroxx mobile': 'ECO Viroxx mobile',

            'ICT & Telematics': 'ICT & Telematics',
            'ECO Telematics': 'ECO Telematics',

            'Training & Services': 'Training & Services',
            "Driver's Safety": "Driver's Safety",
            'Technical Training': 'Technical Training',

            'About': 'About',
            'Group': 'Group',
            'History': 'History',
            'Management & Board': 'Management & Board',
            'Environment & Sustainability': 'Environment & Sustainability',
            'Ethics': 'Ethics',
            'Gender & Equality Strategy': 'Gender & Equality Strategy',

            'Research': 'Research',
            'CEST Africa': 'CEST Africa',
            'EUPARSIP': 'EUPARSIP',
            'News': 'News',

            'Contact': 'Contact',
            'contact-label': 'Get in touch with us',
        }
    },
    fr: {
        translation: {
            'GREEN BUSES': 'BUS VERTS',
            'SAFE AIR': 'AIR SÛR',
            'TRAINING & SERVICES': 'FORMATION ET SERVICES',
            'TELEMATICS & ICT': 'TÉLÉMATIQUES et TIC',

            'Green Buses': 'Bus verts',
            'Green Technology': 'Technologie verte',
            'Public Transports': 'Transports publics',
            'Private Operators': 'Opérateurs privés',
            'ECO Bus Simulators': 'ECO Bus Simulateurs',
            'Bus Depots': 'Dépôts d\'autobus',
            'ECO Bus Scanners': 'ECO Scanners de bus',

            'Safe Air': 'De l\'air sûr',
            'ECO Viroxx mobile': 'ECO Viroxx mobile',

            'Telematics & ICT': 'Télématique et TIC',
            'ECO Telematics': 'ECO Telematics',

            'Training & Services': 'Formation et services',
            "Driver's Safety": "Sécurité des conducteurs  Entraînement",
            'Technical Training': 'Formation technique',

            'About': 'À propos',
            'Group': 'Group',
            'History': 'History',
            'Management & Board': 'Management & Board',
            'Environment & Sustainability': 'Environment & Sustainability',
            'Ethics': 'Ethics',
            'Gender & Equality Strategy': 'Gender & Equality Strategy',

            'Research': 'Recherche',
            'CEST Africa': 'CEST Afrique',
            'EUPARSIP': 'EUPARSIP',
            'News': 'Nouvelles',

            'Contact': 'Contact',
            'contact-label': 'Contactez-nous',
        }
    },
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false,
        resources: resources,
        lng: 'en',
        fallbackLng: 'en',
    });

export default i18n;