import React from 'react'
import { makeStyles } from '@material-ui/core/styles';


import { Box, Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    footerWrap: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        },
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center',
        },
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        color: 'grey',
    },
    linkWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    imageWrap: {
        textDecoration: 'none',
        color: 'grey',
        marginRight: '0.5em',
    },
    rights: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    copyright: {
        height: '0.7em'
    },
}));

const FooterLayout = (props) => {
    const classes = useStyles();

    return (
        <Container className={classes.footerWrap}>
            <Typography>
                360 <b>ECO</b> Group
            </Typography>
            <Box className={classes.rights}>
                <Typography style={{ marginRight: '.3em' }}>
                    © 360 <b>ECO</b> Group 2021
                </Typography>
                <Typography>
                    - All rights reserved
                </Typography>
            </Box>
            <div className={classes.linkWrapper}>
                <Link to="/imprint" className={classes.imageWrap}>
                    Imprint
                </Link>
                <Link to="/private-policy" className={classes.imageWrap}>
                    Private Policy
                </Link>
                <Link to="/terms-of-use" className={classes.imageWrap}>
                    Terms of Use
                </Link>
            </div>
        </Container>
    )
}

export default FooterLayout
