import React, { useEffect, useState, useContext } from 'react'

import PageLayout from '../../components/PageLayout/PageLayout';
import { fetchAPI } from "../../config/api";

import NavBarContext from '../../contexts/NavBarContext';
import { useTranslation } from 'react-i18next';



import { useLocation, useHistory } from "react-router-dom";

const Page = () => {

    const { i18n } = useTranslation();

    const location = useLocation();

    const history = useHistory();

    const [navigationInfo, setNavigationInfo] = useContext(NavBarContext);

    const [pageContent, setPageContent] = useState();

    useEffect(() => {

        function getPageIdFromContext(info, lvl) {

            if (lvl > 4) {
                return null;
            }

            const pageUrl = location.pathname;

            let selectedNavInfo = null;

            let i;
            for (i in info) {
                const navInfo = info[i];

                let navPathName = navInfo.path;

                if (navPathName.includes('products-and-services')) {
                    navPathName = navPathName.replace('/products-and-services', '')
                }

                if (navPathName === pageUrl) {
                    selectedNavInfo = navInfo;
                    if (selectedNavInfo.items.length === 1) {
                        history.push(navInfo.items[0].path.replace('/products-and-services', ''))
                    }

                    return selectedNavInfo;
                }

                selectedNavInfo = getPageIdFromContext(navInfo.items, lvl + 1);

                if (selectedNavInfo) {
                    break;
                }
            };

            return selectedNavInfo;
        }

        const fetchPageInfoById = async () => {

            const page = getPageIdFromContext(navigationInfo, 0);

            let pageId = page && page.related.id

            if (!pageId) {
                history.push('/pnf')
            }

            let data = await fetchAPI(`/pages/${pageId}`);

            if (i18n.language !== 'en') {
                const info = data.localizations.filter(test => test.locale === i18n.language)[0];

                if (info) {
                    data = await fetchAPI(`/pages/${info.id}`)
                }
            }

            setPageContent(data);

        }

        navigationInfo && fetchPageInfoById();

    }, [location, history, navigationInfo, setNavigationInfo, i18n.language]);


    return (
        <PageLayout pageContent={pageContent} />
    )
}

export default Page
