import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import TextLayout from './TextLayout';
import ImageLayout from './ImageLayout';
import { Paper } from '@material-ui/core';
import ImageGallery from './ImageGallery/ImageGallery';
import VideoLayout from './VideoLayout';

const useStyles = makeStyles((theme) => ({
    content: {
        alignContent: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(1),
        },
        // margin: theme.spacing(2),
        boxShadow: 'none',
    }
}));

function checkIfContentIsImagesOnly(pageContent) {

    let result = true;

    pageContent && pageContent.Content.forEach(component => {
        let componentType = component.__component;
        if (componentType !== 'post.image') {
            result = false;
        }
    });

    return pageContent && pageContent.Content.length > 0 && result;
}


const TemplatePageLayout = (props) => {
    const classes = useStyles();

    const { pageContent } = props;
    let pageLayout;

    if (checkIfContentIsImagesOnly(pageContent)) {
        pageLayout = <ImageGallery components={pageContent.Content} />
    } else {
        pageLayout = pageContent && pageContent.Content.map(component => {

            let componentType = component.__component;

            let element = null;

            if (componentType === 'post.image') {
                element = <ImageLayout key={component.id} image={component.image} />
            } else if (componentType === 'post.video') {
                element = <VideoLayout key={component.id} url={component.videoUrl} />
            } else {
                element = <TextLayout key={component.id} richText={component.richText} />
            }
            return (
                element
            )

        })
    }

    return (
        <Paper className={classes.content}>
            {pageLayout}
        </Paper>
    )
}

export default TemplatePageLayout