import React from 'react'
import { Grid, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Locales from '../../containers/ResponsiveToolbar/Locales';
import companyName from '../../icons/companyName.svg';
import NavigationPages from '../../containers/ResponsiveToolbar/NavigationPages';

import { Link } from "react-router-dom";

const drawerWidth = '315px';


const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
        borderRight: 'none',
        left: 'auto',
    },
}));



const EcoSideBar = (props) => {

    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;


    const classes = useStyles();

    return (
        <Drawer
            container={container}
            className={classes.drawer}
            variant="permanent"
            anchor="left"
            classes={{ paper: classes.drawerPaper }}
            PaperProps={{
                style: { overflowX: "none", overflowY: "auto" }
            }}>
            <Grid container direction="column" justifyContent="flex-start">
                <Link to="/">
                    <img src={companyName} alt="360 ECO GROUP" />
                </Link>
                <Locales />
                <NavigationPages />
            </Grid>
        </Drawer>
    )
}

export default EcoSideBar
