import React, { useEffect, useState } from 'react'

import SocialMediaIcons from '../../components/ResponsiveToolbar/SocialMediaIcons';
import { fetchAPI } from "../../config/api";

const SocialAccounts = () => {
    const [socialAccounts, setSocicalAccounts] = useState([]);

    useEffect(() => {
        fetchAPI("/social-media").then(data => {
            setSocicalAccounts(data);
        })
    }, [])

    return (
        <SocialMediaIcons socialAccounts={socialAccounts} />
    )
}

export default SocialAccounts
