import React from 'react';

import './InfoPageStyle.css'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';



const verticalAlign = ["flex-start", "flex-start", "flex-end", "flex-end"];
const horizontalAlign = ["flex-start", "flex-end", "flex-start", "flex-end"];

const colorArray = ["#70AC2E", "#0085D2", "#C6F51B", "#48494B"]

const useStyles = makeStyles((theme) => ({
    paper: props => ({
        display: "flex",
        backgroundColor: colorArray[props.id],
        [theme.breakpoints.up('sm')]: {
            alignItems: verticalAlign[props.id],
            justifyContent: horizontalAlign[props.id],
        },
        padding: '1%',
        aspectRatio: "470/240",
        '&:hover': {
            cursor: 'pointer',
        },
    }),
    productName: props => ({
        color: "white",
        textDecoration: "none",
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            textAlign: horizontalAlign[props.id].replace('flex-', ''),
        },
        display: 'flex',
        flexWrap: 'wrap',


    }),
}));

let theme = createTheme();
theme = responsiveFontSizes(theme);

const InfoPage = (props) => {

    const classes = useStyles(props);

    const { t } = useTranslation();

    const labelLocalized = t(props.label);

    const labelArray = labelLocalized.split('&')



    const labelElement1 =
        <Box color={props.black ? "black" : "white"} style={{ paddingRight: '0.3em' }}>
            {labelArray[0]}
        </Box>

    const labelElement2 = labelArray.length === 2 &&
        <Box color={props.black ? "black" : "white"}>
            {"& " + labelArray[1]}
        </Box>

    return (
        <ThemeProvider theme={theme}>
            <Paper
                elevation={0}
                className={classes.paper}
                onClick={props.onProductClick}
            >
                <Typography
                    variant="h5"
                    className={classes.productName}
                >
                    {labelElement1}
                    {labelElement2}
                </Typography>
            </Paper>
        </ThemeProvider>
    )
}

export default InfoPage
