import React from 'react'
import { ImageListItem, makeStyles } from '@material-ui/core';

import InfoItem from './InfoItem';
import ImageLayout from './ImageLayout';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        paddingLeft: '2vw',
        paddingBottom: '2vw',
    },
}));

const BrowserItem = (props) => {

    const classes = useStyles();

    let infoElement = props.isInfoShown && <InfoItem item={props.item} isBrowser={true} getUrl={props.getUrl} />

    return (
        <ImageListItem className={classes.container} onMouseEnter={props.showInfo} onMouseLeave={props.hideInfo}>
            <ImageLayout image={props.item.image} title={props.item.title} />
            {infoElement}
        </ImageListItem>
    )
}

export default BrowserItem
