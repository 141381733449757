import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Grid, List, ListItem } from '@material-ui/core';
import NavigationItem from './NavigationItem';
import SubNavigationItem from './SubNavigationItem';

import SocialAccounts from '../../containers/ResponsiveToolbar/SocialAccounts';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    toolbarText: {
        "&:hover": {
            color: 'grey',
            backgroundColor: 'white'
        },
        "&:click": {
            transition: 'none'
        },
        transition: 'none',
        paddingTop: 0,
        paddingBottom: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}));


const NavigationItems = (props) => {

    const { t } = useTranslation();

    const classes = useStyles();

    let listItems;
    let productItems = null;

    if (props.navigationInfo) {

        listItems = props.navigationInfo.filter(info => info && info.menuAttached && info.title !== "Products & Services").map(navItem =>
            <React.Fragment key={navItem.id} >
                <ListItem button key={navItem.id} className={classes.toolbarText} onClick={() => props.redirectTo(navItem.path)}>
                    <NavigationItem isSelected={navItem.path === props.activeNavItem || props.activeNavItem.includes(navItem.path + "/")}>
                        {t(navItem.title)}
                    </NavigationItem>
                </ListItem >
                {props.activeNavItem.includes(navItem.path) && navItem.items.map(child =>
                    <ListItem button key={child.id} className={classes.toolbarText} onClick={() => props.redirectTo(child.path)}>
                        <SubNavigationItem isSelected={child.path === props.activeNavItem}>
                            {t(child.title)}
                        </SubNavigationItem>
                    </ListItem >

                )}
            </React.Fragment>
        );



        const psObject = props.navigationInfo?.filter(info => info && info.menuAttached && info.title === "Products & Services")[0];

        if (psObject) {

            const productObjects = psObject.items;
            productItems = productObjects.map(productObject => {

                function productsURL(originalUrl) {
                    return originalUrl.replace(psObject.path, '');
                }

                let shortUrl = productsURL(productObject.path)

                return (
                    <React.Fragment key={productObject.id}>
                        <ListItem button key={productObject.id} className={classes.toolbarText} onClick={() => props.redirectTo(productsURL(shortUrl))}>
                            <NavigationItem isSelected={shortUrl === props.activeNavItem}>
                                {t(productObject.title)}
                            </NavigationItem>
                        </ListItem >
                        {props.activeNavItem.includes(shortUrl) && productObject.items.map(child => {

                            let shortUrl = productsURL(child.path)
                            return (
                                <ListItem button key={child.id} className={classes.toolbarText} onClick={() => props.redirectTo(shortUrl)}>
                                    <SubNavigationItem isSelected={shortUrl === props.activeNavItem}>
                                        {t(child.title)}
                                    </SubNavigationItem>
                                </ListItem >
                            )
                        })}
                    </React.Fragment>
                )
            });
        }
    }

    return (
        <React.Fragment>
            <div className={classes.divider} />
            <Grid item>
                <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                    {productItems}
                </List>
            </Grid>
            <div className={classes.divider} />
            <Grid item>
                <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                    {listItems}
                </List>
            </Grid>
            <div className={classes.divider} />
            <SocialAccounts />
        </React.Fragment>
    )
}

export default NavigationItems
