import React from 'react'
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    localeSelector: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: theme.spacing(2),
    },
    locale: {
        color: "black",
        '&:hover': {
            cursor: 'pointer'
        },
        paddingRight: theme.spacing(1),
    },
    divider: {
        paddingRight: theme.spacing(1),
    },
    selectedLocale: {
        paddingRight: theme.spacing(1),
    },
}));

const LocaleSelector = (props) => {
    const classes = useStyles();

    const { activeLocale } = props;

    const { supportedLocales } = props;

    const { setActiveLocale } = props;

    let count = -1;

    const LocaleComponent = (props) => {
        count = count + 1;
        return (

            <React.Fragment>
                {
                    (props.locale !== 'en') &&
                    < Box className={classes.divider}>
                        |
                    </Box>
                }
                {
                    (activeLocale === props.locale) ?
                        <Box className={classes.selectedLocale} fontWeight={activeLocale === props.locale ? 'fontWeightBold' : 'fontWeightLight'} nowrap='true'>
                            {props.locale}
                        </Box> :
                        <Box onClick={() => props.setActive(props.locale)} className={classes.locale} underline={true ? 'none' : 'hover'}>
                            {props.locale}
                        </Box>
                }

            </React.Fragment >
        )
    };

    return (
        <Box className={classes.localeSelector}>
            {supportedLocales && (supportedLocales.length > 1) ?
                supportedLocales.map(locale => (
                    <LocaleComponent key={locale} locale={locale} setActive={() => setActiveLocale(locale)} />
                ))
                : null}
        </Box>
    )
}

export default LocaleSelector
