import { Typography } from '@material-ui/core'
import React from 'react'
import logo from '../../icons/logo.svg'
import { Link } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    grid: {
        display: "flex",
    }, title: {
        color: theme.palette.grey[700],
    },
    content: {
        color: theme.palette.grey[500]
    }

}));

let theme = createTheme();
theme = responsiveFontSizes(theme);

const PageNotFound = () => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            alignContent="center"
        >
            <ThemeProvider theme={theme}>
                <Grid item className={classes.grid}>
                    <Typography variant="h5" className={classes.title}>
                        Page Under Construction
                </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography variant="h6" className={classes.content}>
                        The page you are currently accessing is under construction. We apologize for the inconvenience but something good is getting ready.
                </Typography>
                    <Link to="/">
                        <Typography variant="h6">
                            Return to homepage
                    </Typography>
                    </Link>
                </Grid>
                <Grid item className={classes.grid}>
                    <img src={logo} alt="logo" />
                </Grid>
            </ThemeProvider>
        </Grid>
    )
}

export default PageNotFound