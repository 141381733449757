import React from 'react'
import { makeStyles } from '@material-ui/core/styles';


import { Container, Grid, Typography } from '@material-ui/core';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    main: {
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(2),
        },
        flexDirection: "column",
        flexWrap: 'noWrap',
        alignItems: 'center'
    },
    contactInfo: {
        display: 'flex',
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: "row"
        },
    },
    contactWrapper: {
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(5),
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: theme.spacing(2),
        },
    },
    iconWrapper: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'left',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'center',
        },
        display: 'flex',
    },
    icon: {
        fontSize: '3em'
    },
    detailsWrapper: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'left',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'center',
        },
        display: 'flex',
    },
}));

const ContactLayout = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const { contactInfo } = props;

    let contactDetailsElement = contactInfo &&
        <Grid item className={classes.contactInfo}>
            <Container className={classes.contactWrapper}>
                <div className={classes.iconWrapper}>
                    <LocationOnIcon className={classes.icon} />
                </div>
                <Typography variant="h5" className={classes.detailsWrapper}>
                    {contactInfo.Address}
                </Typography>
            </Container>

            <Container className={classes.contactWrapper}>
                <div className={classes.iconWrapper}>
                    <PhoneIcon className={classes.icon} />
                </div>
                <Typography variant="h5" className={classes.detailsWrapper}>
                    {contactInfo.Telephone}
                </Typography>
            </Container>

            <Container className={classes.contactWrapper}>
                <div className={classes.iconWrapper}>
                    <EmailIcon className={classes.icon} />
                </div>
                <Typography variant="h5" className={classes.detailsWrapper}>
                    {contactInfo.Email}
                </Typography>
            </Container>
        </Grid>;

    return (
        <Container className={classes.content}>
            <Grid container className={classes.main} direction='column'>
                <Grid item>
                    <Typography variant="h2">
                        {t('contact-label')}
                    </Typography>
                </Grid>
                {contactDetailsElement}
            </Grid>
        </Container>
    )
}

export default ContactLayout
