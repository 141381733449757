import React from 'react'

import { Typography, Box } from '@material-ui/core';

const NavigationItem = (props) => {

    return (
        <Typography key={props.id} variant="subtitle1">
            <Box key={props.id} fontWeight={props.isSelected ? "fontWeightBold" : "fontWeightRegular"}>
                {props.children}
            </Box>
        </Typography>
    )
}

export default NavigationItem
