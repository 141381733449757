import React, { useContext } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import NavifationItems from '../../components/ResponsiveToolbar/NavigationItems';

import NavBarContext from '../../contexts/NavBarContext';

const NavigationPages = (props) => {

    const location = useLocation();

    let history = useHistory();

    const visitLink = (url) => {
        props.closeDrawer && props.closeDrawer();
        history.push(url);
    }

    const [navigationInfo] = useContext(NavBarContext);

    return (
        <NavifationItems
            navigationInfo={navigationInfo}
            redirectTo={visitLink}
            activeNavItem={location.pathname} />
    )
}

export default NavigationPages
