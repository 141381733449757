import React from 'react'
import ProductList from './Products/ProductList';

const Home = () => {
    return (
        <ProductList />
    )
}

export default Home
