import React from 'react'

import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    subnav: {
        paddingLeft: "2em",
    },
    selectedSubItem: {
        color: 'grey'
    }
});


const NavigationItem = (props) => {

    const classes = useStyles();

    return (
        <Typography key={props.id} variant="subtitle1" className={`${classes.subnav} ${props.isSelected ? classes.selectedSubItem : classes.notSelectedSubItem}`}>
            {props.children}
        </Typography>
    )
}

export default NavigationItem
