import React from 'react'
import ReactMarkdown from 'react-markdown'


const TextLayout = (props) => {

    let { richText } = props;
    return (
        <ReactMarkdown >{richText}</ReactMarkdown>
    )
}

export default TextLayout
