import React from 'react'

import { Hidden } from '@material-ui/core';

import EcoBar from '../../containers/ResponsiveToolbar/EcoBar';
import EcoSideBar from './EcoSideBar';

const ResponsiveToolbar = () => {

    return (
        <React.Fragment>
            <Hidden lgUp>
                <EcoBar />
            </Hidden>
            <Hidden mdDown>
                <EcoSideBar />
            </Hidden>
        </React.Fragment>
    )
}

export default ResponsiveToolbar
