import React from 'react'
import { Grid, Drawer, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


import CloseIcon from '@material-ui/icons/Close';

import NavigationPages from '../../containers/ResponsiveToolbar/NavigationPages';
import LocaleSelector from './LocaleSelector';
import companyName from '../../icons/companyName.svg';

import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
    },
    drawerHeader: {
        display: 'flex',
        alignSelf: 'center',
        flexDirection: 'row',
        width: "100%",
    },
    drawerImage: {
        textAlign: 'center',
        flexGrow: 1,
        paddingLeft: '48px',
    },
}));

const EcoDrawerBar = (props) => {
    const classes = useStyles();

    return (
        <Drawer
            className={classes.mobileDrawer}
            anchor="right"
            open={props.isDrawerOpen}
            onClose={() => props.handleDrawerClose()}
            ModalProps={{
                keepMounted: true,
            }}
            PaperProps={{
                style: { width: "100%" }
            }}
        >
            <Grid className={classes.toolbarList} container direction="column" justifyContent="flex-start" alignContent="center">
                <Grid item className={classes.drawerHeader} >
                    <Link to="/" className={classes.drawerImage}>
                        <img src={companyName} alt="360 ECO GROUP" />
                    </Link>
                    <IconButton onClick={props.handleDrawerClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <LocaleSelector />
                <NavigationPages closeDrawer={props.handleDrawerClose} />
            </Grid>
        </Drawer>


    )
}

export default EcoDrawerBar
