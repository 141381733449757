import React from 'react'
import MenuIcon from '@material-ui/icons/Menu';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import EcoDrawerBar from './EcoDrawerBar';
import { makeStyles } from '@material-ui/core/styles';
import companyName from '../../icons/companyName.svg';

import { Link } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    ecoBar: {
        backgroundColor: 'white',
        color: 'black'
    },
    toolbarArea: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    toolbarImage: {
        textAlign: 'center',
        flexGrow: 1,
    },
    toolbarIcon: {
        flexGrow: 0,
    }
}));
const EcoBar = (props) => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <AppBar className={classes.ecoBar} elevation={0}>
                <Toolbar className={classes.toolbarArea}>
                    <Link to="/" className={classes.toolbarImage}>
                        <img src={companyName} alt="360 ECO GROUP" />
                    </Link>
                    <IconButton
                        className={classes.toolbarIcon}
                        edge="end"
                        aria-label="open drawer"
                        onClick={props.handleDrawerOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <EcoDrawerBar
                isDrawerOpen={props.isDrawerOpen}
                handleDrawerClose={props.handleDrawerClose}
                handleDrawerOpen={props.handleDrawerOpen}
            />
        </React.Fragment>
    )
}

export default EcoBar
