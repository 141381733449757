import React, { useState, useEffect } from 'react';
import NewsLayout from '../../components/News/NewsLayout'
import { fetchAPI } from "../../config/api";
import { useTranslation } from 'react-i18next';

const News = () => {

    const { i18n } = useTranslation();

    const [posts, setPosts] = useState();

    useEffect(() => {
        fetchAPI(`/posts?_locale=${i18n.language}&_sort=published_at:DESC`).then(data => {
            setPosts(data);
        });
    }, [i18n.language]);

    return (
        <NewsLayout posts={posts} />
    )
}

export default News
