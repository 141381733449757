import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

import ProductInfo from '../../containers/Products/ProductInfo';
import { Grid } from '@material-ui/core';
import Logo from '../Logo'


const useStyles = makeStyles((theme) => ({
    container: {
        alignContent: 'center',
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(0),
        },
        position: 'relative',
    },
    gridPaper: {
        padding: theme.spacing(1),
        // borderStyle: 'solid',
    },
}));

const ProductList = (props) => {
    const classes = useStyles();

    return (
        <Grid className={classes.container} container>
            <Logo />
            <Grid item className={classes.gridPaper} sm={6} xs={12} >
                <ProductInfo id={0} label="GREEN BUSES" />
            </Grid>
            <Grid item className={classes.gridPaper} sm={6} xs={12}>
                <ProductInfo id={1} label="SAFE AIR" />
            </Grid >
            <Grid item className={classes.gridPaper} sm={6} xs={12}>
                <ProductInfo id={2} label="TELEMATICS & ICT" black />
            </Grid>
            <Grid item className={classes.gridPaper} sm={6} xs={12}>
                <ProductInfo id={3} label="TRAINING & SERVICES" />
            </Grid>
        </Grid>
    )
}

export default ProductList
