import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import InfoPage from '../../components/Products/InfoPage';
import NavBarContext from '../../contexts/NavBarContext';

const ProductInfo = (props) => {

    let history = useHistory();

    const [navigationInfo] = useContext(NavBarContext);

    const [url, setUrl] = useState("#");

    const onProductClickHandler = () => {
        history.push(url)
    }

    useEffect(() => {

        if (navigationInfo) {
            let parentObject = navigationInfo.filter(nav => nav.title === 'Products & Services')[0];

            let product = parentObject.items.filter(item => item.title.toUpperCase() === props.label)[0]

            let url = product.path.replace(parentObject.path, '');

            setUrl(url);
        }
    }, [navigationInfo, props.label])

    return (
        <InfoPage
            {...props}
            onProductClick={onProductClickHandler} />
    )
}

export default ProductInfo
