import React from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, ImageListItem, makeStyles } from '@material-ui/core';
import InfoItem from './InfoItem';
import ImageLayout from './ImageLayout';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        alignItems: 'center',
        paddingLeft: '2vw',
        paddingBottom: '2vw',
    },
    toggle: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        margin: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white'
    },
    text: {
        padding: theme.spacing(2),
        backgroundColor: 'white',
        height: '100%'
    }
}));
const MobileItem = (props) => {

    const classes = useStyles();

    let infoElement = props.isInfoShown &&
        <InfoItem item={props.item} isBrowser={false} action={props.hideInfo} getUrl={props.getUrl} />

    let toggleElement = !props.isInfoShown &&
        <IconButton className={classes.toggle} onClick={props.showInfo} size="small">
            <MoreVertIcon style={{ color: 'grey' }} fontSize="medium" />
        </IconButton>

    return (
        <ImageListItem className={classes.container} >
            <div onClick={props.showInfo}>
                <ImageLayout image={props.item.image} />
            </div>

            {toggleElement}

            {infoElement}
        </ImageListItem>
    )
}

export default MobileItem
