import { ImageList, makeStyles, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ImageGalleryItem from '../../../containers/Page/ImageGalleryItem';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    imageList: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        },
    }
}));


const ImageGallery = (props) => {

    const classes = useStyles();
    const theme = useTheme();


    const { components } = props;

    const matches_md = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <ImageList className={classes.imageList} cols={matches_md ? 1 : 2}>
            {components.map(item => (
                item && <ImageGalleryItem key={item.id} item={item} />
            ))}
        </ImageList>
    )
}

export default ImageGallery
